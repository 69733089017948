import React from "react";
import { createRef, useRef } from "react";
// reactstrap components
import {
    Container,
    Row,
    Col,
  } from "reactstrap";
import image from '../../assets/img/Logistics.jpg'
// core components

function Slide6 (props) {
  let pageHeader = React.createRef();
  const {contactUsRef}=props
  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });



  const executeScroll = () => contactUsRef.current.scrollIntoView({ behavior: "smooth" });  
  return (
    <>
      <div
        style={{
        
         backgroundImage: `url(${image})`,
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="filter" />
        <Container>
         
        <Row >
         
        <Col md={'8'} className="motto">
             <h2 style={{color:'white',fontWeight:'bold'}}>
             OUR ADVANTAGE:
             </h2>
             </Col>
             <Col md={'7'} className="motto">
             <h3 style={{color:'white',fontWeight:'bold'}}>
             BIG DATA FOR SMARTER, FASTER, MORE CUSTOMER CENTRIC & SUSTAINABLE OPERATIONS.
             </h3>
             <br/>
             </Col>
           <Col md={'7'} className="motto">
           <h5  style={{color:'white'}}>
           Our solution is your digital transformation and your first critical step into Industry 4.0 which is the aspiration of leading global companies to ensure their sustainability and existence for the future.
  </h5>
             </Col>
             </Row>
        </Container>
      </div>
    </>
  );
}

export default Slide6;
