import React from "react";
import { createRef, useRef } from "react";
// reactstrap components
import {
    Container,
    Row,
    Col,
  } from "reactstrap";
import image from '../../assets/img/04.jpg'
// core components

function Slide2 (props) {
  let pageHeader = React.createRef();
  const {contactUsRef}=props
  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });



  const executeScroll = () => contactUsRef.current.scrollIntoView({ behavior: "smooth" });  
  return (
    <>
      <div
        style={{
        
         backgroundImage: `url(${image})`,
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="filter" />
        <Container>
         
        <Row >
         
        <Col md={'8'} className="motto">
             <h2 style={{color:'white',fontWeight:'bold'}}>
             LEARN WHAT LIES BENEATH.
             </h2>
             </Col>
             <Col md={'7'} className="motto">
             <h3 style={{color:'white',fontWeight:'bold'}}>
             JOIN THE RANKS OF THE GROWTH LEADERS, BY CREATING AN AGILE GROWTH ENGINE.
              </h3>
             <br/>
             </Col>
           <Col md={'7'} className="motto">
           <h5  style={{color:'white'}}>
           As more companies produce similar products competition is increasing and eroding margins. You should consider undertaking an accelerated approach that focuses not only on optimizing operations and enhancing competitiveness but on generating more revenue from the same clients and the same products or services.
 </h5>
             </Col>
             </Row>
        </Container>
      </div>
    </>
  );
}

export default Slide2;
