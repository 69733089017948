import { SnapList, SnapItem, useVisibleElements, useScroll, useDragToScroll } from 'react-snaplist-carousel';
import Header from './components/Header';
import Footer from './components/Footer';
import Slide1 from './Slides/Slide1';
import Slide2 from './Slides/Slide2';
import Slide3 from './Slides/Slide3';
import Slide4 from './Slides/Slide4';
import Slide5 from './Slides/Slide5';
import Slide9 from './Slides/Slide9';
import Slide10 from './Slides/Slide10';
import Slide11 from './Slides/Slide11';
import ContactUs from './Slides/ContactUs';
import './App.scss'
import "./assets/scss/paper-kit.scss?v=1.3.0";
import "./assets/demo/demo.css?v=1.3.0";
import "bootstrap/scss/bootstrap.scss";
import 'bootstrap/dist/css/bootstrap.css';
import { useRef } from 'react';
import Slide6 from './Slides/Slide6';
import Slide7 from './Slides/Slide7';
function App() {

    const refSlide2 = useRef(null)
    const refSlide9 = useRef(null)
    const refSlide11 = useRef(null)
    const refContactUS = useRef(null)

    return (
        <div style={{ height: '100vh', width: '100%' }}>
            <Header refSlide2={refSlide2} refSlide9={refSlide9} refSlide11={refSlide11} refContactUS={refContactUS}/>
            <SnapList hideScrollbar={true} direction="vertical" height="100vh" width='100%' style={{ marginTop: '-15px' }}>
                <SnapItem forceStop height="100%" width='100%'>
                    <Slide1 />
                </SnapItem>
                <SnapItem forceStop height="100%" width='100%'>
                    <div  ref={refSlide2}  className='w-100 h-100' style={{height:'100vh'}}>
                    <Slide2/>
                    </div>
                 
                </SnapItem>
                <SnapItem forceStop height="100%" width='100%'>
                    <Slide3 />
                </SnapItem>
                <SnapItem forceStop height="100%" width='100%'>
                <Slide6/>
                </SnapItem>
                <SnapItem forceStop height="100%" width='100%'>
                <Slide7/>
                </SnapItem>
                <SnapItem forceStop height="100%" width='100%'>
                    <Slide4 />
                </SnapItem>
                <SnapItem forceStop height="100%" width='100%'>
                    <Slide5 />
                </SnapItem>
              
  
              
                <SnapItem forceStop height="100%" width='100%'>
                <div ref={refSlide11}   className='w-100 h-100' style={{height:'100vh'}}>
                    <Slide11  />
                    </div>
                </SnapItem>
                
                <SnapItem forceStop height="100%" width='100%'>
                    <div ref={refSlide9}  className='w-100 h-100' style={{height:'100vh'}}>
                    <Slide9  />
                    </div>
                   
                </SnapItem>
                <SnapItem forceStop height="100%" width='100%'>
                    <Slide10 />
                </SnapItem>
                <SnapItem forceStop height="100vh" width='100%'>
                    <div  ref={refContactUS} style={{ width: '100%',height:'100%' }}  className='w-100'>
                        <ContactUs  />
                    </div>
                </SnapItem>
                <SnapItem forceStop height="100%" width='100%'>
                    <Footer />
                </SnapItem>
            </SnapList>
        </div>
    );
}

export default App;
