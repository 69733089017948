import "bootstrap/scss/bootstrap.scss";
import 'bootstrap/dist/css/bootstrap.css';
import React from "react";
import { createRef, useRef } from "react";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Button
} from "reactstrap";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import image from '../../assets/img/DadTimes.jpg'
// core components

function Slide5(props) {
  let pageHeader = React.createRef();
  const { contactUsRef } = props
  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  const executeScroll = () => contactUsRef.current.scrollIntoView({ behavior: "smooth" });
  return (
    <>
      <div
        style={{

          backgroundImage: `url(${image})`,
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >   <div className="filter" />
        <div />

        <Container className="motto ">

          <Row >

            <Col md={'8'}>
              <h2 style={{ color: 'white', fontWeight: 'bold' }}>
              ANALYTICS: 
              </h2>
            </Col>
            <Col md={'7'}>
              <h3 style={{ color: 'white', fontWeight: 'bold' }}>
              SPREAD-HEADING END-TO-END TRANSFORMATION</h3>
              <br />
            </Col>
            <Col md={'6'}>
              <h5 style={{ color: 'white' }}>
              The cost cutting required to address falling revenues has come as a shock to today’s industries. In pursuit of sustainable cost reductions and near- and long-term profitability, today’s managers are rediscovering the spirit of efficiency.
              </h5>
              {/* <Button
                            // onClick={() => {
                            //   executeScrollSlide5()
                            //   // executeScroll()
                            // }}
                            //   href="#ContactUs"
                            className="btn-round "
                            color="success"
                            target="_blank"
                            size="medium"
                            outline
                            style={{fontSize:8,marginRight:'10px'}}
                          > Read More <ArrowForwardIcon fontSize={'small'}/></Button> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Slide5;
