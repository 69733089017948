
import * as React from "react";
import "bootstrap/scss/bootstrap.scss";
import 'bootstrap/dist/css/bootstrap.css';
// importing material UI components

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AppBar from "@mui/material/AppBar";
import { NavLink } from "react-router-dom";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import logo from '../../assets/img/logo6.jpeg'
import { useMediaQuery } from 'react-responsive'

 const Header =(props)=> {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const {refSlide2,refSlide9,refSlide11,refContactUS} =props
  const executeScrollSlide2 = () => refSlide2.current.scrollIntoView({ behavior: "smooth" });  
  const executeScrollSlide9 = () => refSlide9.current.scrollIntoView({ behavior: "smooth" });  
  const executeScrollSlide11 = () => refSlide11.current.scrollIntoView({ behavior: "smooth" });  
  const executeScrollContactUS = () => refContactUS.current.scrollIntoView({ behavior: "smooth" }); 
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  return (
      <AppBar  style={{ background:'white' }}  position='sticky'>
        <Toolbar style={{height:'8vh'}} >
          {/*Inside the IconButton, we 
           can render various icons*/}

          {/* The Typography component applies 
           default font weights and sizes */}
  
          <Typography variant="h6" 
            style={{ color: 'grey',verticalAlign:'middle' }} 
            component="div" sx={{ flexGrow: 0.95 }}
            
            >
              {/* <a href="./" style={{textDecoration:'none',color: 'grey'}}>
                   HugginMunin
              </a>
          */}
         <a href="./">
         <img  src={logo} width={'150px'}/>
         </a>
          </Typography>
          <div style={{marginRight:'10px'}}>
          
        {isTabletOrMobile?null:  <>

          <Button color="inherit" onClick={()=>{executeScrollSlide11()}}  style={{ color: 'grey',textTransform:'none' }} >About Us</Button>
          <Button color="inherit" onClick={()=>{executeScrollSlide9()}}
           style={{ color: 'grey' ,textTransform:'none'}} >Our Method</Button>
 <Button color="inherit" 
 id="demo-positioned-button"
 aria-controls={open ? 'demo-positioned-menu' : undefined}
 aria-haspopup="true"
 aria-expanded={open ? 'true' : undefined}
 onClick={handleClick}
           style={{ color: 'grey' ,textTransform:'none'}} >Our Projects</Button>

          <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
    
      >
        <MenuItem style={{ color: 'grey',textTransform:'none' }}  onClick={()=>{window.location.href = 'http://fleetserve.app'; }}>
        Fleetserve</MenuItem>
        <MenuItem style={{ color: 'grey',textTransform:'none' }}  onClick={()=>{window.location.href = 'http://agricom.app'; }}>
        Agricom</MenuItem>
      </Menu>
   
          </>}
       
        <Button color="inherit"  onClick={()=>{executeScrollSlide2()}} style={{ color: 'grey',textTransform:'none' }} > Our Services </Button>
          <Button color="inherit"  onClick={()=>{executeScrollContactUS()}} style={{ color: 'grey',textTransform:'none' }} > Contact Us </Button>
        
          </div>
               {/* <Button    href="https://main.agricom.app" 
               style={{ color: 'black' 
             //  ,backgroundColor:'#5fb47c'
              }} 
              
               > Sign In</Button> */}
        </Toolbar>
      </AppBar>
  );
}
export default Header