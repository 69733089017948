import React from "react";
import { createRef, useRef } from "react";
// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import image from '../../assets/img/Digital.png'
// core components

function Slide4(props) {
  let pageHeader = React.createRef();
  const { contactUsRef } = props
  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  const executeScroll = () => contactUsRef.current.scrollIntoView({ behavior: "smooth" });
  return (
    <>
      <div
        style={{

          backgroundImage: `url(${image})`,
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >   <div className="filter" />
        <div />

        <Container className="motto ">

          <Row >

            <Col md={'8'}>
              <h2 style={{ color: 'white', fontWeight: 'bold' }}>
              DIGITAL STRATEGY: 
              </h2>
            </Col>
            <Col md={'7'}>
              <h3 style={{ color: 'white', fontWeight: 'bold' }}>
              PLANNING FOR THE FIGHTS YOU HAVE TO WIN! </h3>
              <br />
            </Col>
            <Col md={'6'}>
              <h5 style={{ color: 'white' }}>
              Yesterday’s tentative approaches won’t deliver. You need absolute clarity, galvanized leadership, unparalleled agility & resolve to bet boldly. This requires sophisticated modeling and intense cooperation, it is usually better to have and third party involved to achieve the required results and capture new growth.
              </h5>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Slide4;
