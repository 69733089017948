import React from "react";
import { createRef, useRef } from "react";
// reactstrap components
import {
    Container,
    Row,
    Col,
  } from "reactstrap";
import image from '../../assets/img/f1.jpeg'
import './styles.scss'
// core components

function Slide2 (props) {
  let pageHeader = React.createRef();
  const {contactUsRef}=props
  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });



  const executeScroll = () => contactUsRef.current.scrollIntoView({ behavior: "smooth" });  
  return (
    <>
      <div
        style={{
        
         backgroundImage: `url(${image})`,
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="filter" />
        <Container>
         
        <Row >
         
        <Col md={'8'} className="motto">
             <h2 style={{color:'white',fontWeight:'bold'}}>
             DATA DRIVEN CULTURES, 
THE WAY INTO THE FUTURE


             </h2>
             </Col>
             <Col md={'7'} className="motto">
             <h4 style={{color:'white',fontWeight:'bold'}}>
             Our solution is your digital transformation, and your first critical step into Industry 4.0 which is by itself the aspiration of leading global companies to ensure their sustainability and existence for the future.


             </h4>
             <br/>
             </Col>
           <Col md={'7'} className="motto">
           <h5  style={{color:'white'}}>
           It is our goal to harmonize our services with your specifications in such a way that you obtain the maximum value added – whether the services consist of chemical transport operations, warehousing for dangerous substances for the chemical or the oil and gas industries.
            </h5>
             </Col>
             </Row>
        </Container>
      </div>
    </>
  );
}

export default Slide2;
