import React, { useState, useContext, useEffect } from "react";



import { Redirect, Switch } from "react-router-dom";


import {

  Route
} from "react-router-dom";
import App from "../App";



export default function AppNavigation() {



    return (<>

      <Switch >
      <App/> 
     
       <Route element={<App/> } /> 
      </Switch>
    </>)
 }

function About() {
  return <h2>About</h2>;
}

function Users() {
  return <h2>Users</h2>;
}
