import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.scss'
import { useMediaQuery } from 'react-responsive'
import logo from '../../assets/img/logo3.jpeg'

const Footer =()=> {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 924px)' })
  return (
    <footer className="ct-footer container-fluid w-100 h-100" >
    <div className="container-fluid w-100" >
   
      <ul className="ct-footer-list text-center-sm">
        <li>
          <h2 className="ct-footer-list-header font">Connect With Us</h2>
          <br/>
          <ul>
            <li>
              <h7 href="">info@HugginMunin.com</h7>
            </li>
            <li>
              <h7 href="">Mon - Fri: 9 am- 6 pm</h7>
            </li>
            <li>
              <h7 href="">+961 3 660 229</h7>
            </li>
            <li>
              <h7 href="">Beirut, Lebanon</h7>
            </li>
          </ul>
        </li>
        <li>
          <h2 className="ct-footer-list-header font">About Us</h2>
          <br/>
          <ul>
            <li>
              <h7 href="">We are a team of passionate people whose goal is to accelerate change in the food and agriculture sector by driving efficiencies, fuelling growth, shaping industries, and increasing organizational effeciveness.</h7>
            </li>
          </ul>
        </li>
       
     
        <li style={!isTabletOrMobile?{paddingLeft:'10%'}:null}>
{/*       
        <img src={logo} style={{width:'200px'}}/> */}
     
        <h2 className="ct-footer-list-header font">Huggin Munin</h2>
        <br/>
          <ul>
            <li >
              <h7 href="" style={{color:'grey'}}>Huggin Munnin Company</h7>
            </li>
            <li>
              <h7 href=""  style={{color:'grey'}}><br/>
              Unit 3D North Point Business Park<br/>
              New Mallow Road<br/>
              Cork, Ireland
              
              </h7>
            </li>
          </ul>
        </li>
      </ul>
    
    </div>
 
  </footer>
  )
}
export default Footer;